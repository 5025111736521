import { asNonEmptyString } from "../../util/util_extensions";
import { BCEventRangeOpeningHours, BCEventType } from "../../model/event";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { EventFormState } from "../../model/eventFormState";
import StringUtils from "../../util/StringUtils";
import ArrayUtils from "../../util/ArrayUtils";

function interpolateColor(color1, color2, factor) {
  if (arguments.length < 3) {
    factor = 0.5;
  }
  const result = color1.slice();
  for (let i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
  }
  return result;
}

export default function useEventFormValidation() {
  const eventFormState = useSelector<RootState, EventFormState>((rootState) => rootState.eventFormState);

  let isMissingBaseData = false;
  if (asNonEmptyString(eventFormState.event.title) === null) {
    isMissingBaseData = true;
  }
  if (asNonEmptyString(eventFormState.event.summary) === null) {
    isMissingBaseData = true;
  }
  if (asNonEmptyString(eventFormState.event.description) === null) {
    isMissingBaseData = true;
  }
  if (ArrayUtils.isNullOrEmpty(eventFormState.event.categories)) {
    isMissingBaseData = true;
  }
  if (ArrayUtils.isNullOrEmpty(eventFormState.event.tags)) {
    isMissingBaseData = true;
  }
  const isMissingLocations = ArrayUtils.isNullOrEmpty(eventFormState.event.locations);
  let isMissingTimes: boolean;
  if (eventFormState.event.type === BCEventType.Dedicated) {
    if (ArrayUtils.isNullOrEmpty(eventFormState.event.dedicated_times)) {
      isMissingTimes = true;
    } else if (
      eventFormState.event.dedicated_times.length === 1 &&
      (!eventFormState.event.dedicated_times[0].start_date || !eventFormState.event.dedicated_times[0].end_date)
    ) {
      isMissingTimes = true;
    }
  } else {
    let hours = 0;
    for (const range of eventFormState.event.event_availability_ranges) {
      hours = hours + filterForValidOpeningHours(range.opening_hours).length;
    }
    isMissingTimes = ArrayUtils.isNullOrEmpty(eventFormState.event.event_availability_ranges) || hours === 0;
  }
  const isMissingImages = ArrayUtils.isNullOrEmpty(eventFormState.event.media_items);

  const sections = [isMissingLocations, isMissingImages, isMissingBaseData, isMissingTimes];

  let missing = 0;
  sections.forEach((item) => {
    if (item === true) {
      missing = missing + 1;
    }
  });

  const totalFieldCount = 37;
  let providedFieldCount = 0;

  if (eventFormState.event.type === BCEventType.Dedicated) {
    if (ArrayUtils.isNullOrEmpty(eventFormState.event.dedicated_times)) {
    } else if (
      eventFormState.event.dedicated_times.length === 1 &&
      (!eventFormState.event.dedicated_times[0].start_date || !eventFormState.event.dedicated_times[0].end_date)
    ) {
    } else {
      providedFieldCount += 1;
    }
  } else {
    if (!ArrayUtils.isNullOrEmpty(eventFormState.event.event_availability_ranges)) {
      let hours = 0;
      for (const range of eventFormState.event.event_availability_ranges) {
        hours = hours + filterForValidOpeningHours(range.opening_hours).length;
      }
      if (hours > 0) {
        providedFieldCount += 1;
      }
    }
  }

  if (asNonEmptyString(eventFormState.event.title)) {
    providedFieldCount += 1;
  }
  if (asNonEmptyString(eventFormState.event.summary)) {
    providedFieldCount += 1;
  }
  if (asNonEmptyString(eventFormState.event.description)) {
    providedFieldCount += 1;
  }

  if (eventFormState.event.price_in_cents !== null) {
    providedFieldCount += 1;
  }
  if (eventFormState.event.price_in_cents_3yr !== null) {
    providedFieldCount += 1;
  }
  if (eventFormState.event.price_in_cents_8yr !== null) {
    providedFieldCount += 1;
  }
  if (eventFormState.event.price_in_cents_adult !== null) {
    providedFieldCount += 1;
  }
  if (asNonEmptyString(eventFormState.event.price_currency)) {
    providedFieldCount += 1;
  }

  if (asNonEmptyString(eventFormState.event.phone)) {
    providedFieldCount += 1;
  }
  if (asNonEmptyString(eventFormState.event.website_url)) {
    providedFieldCount += 1;
  }

  if (eventFormState.event.age_from !== null) {
    providedFieldCount += 1;
  }
  if (eventFormState.event.age_to !== null) {
    providedFieldCount += 1;
  }

  if (!ArrayUtils.isNullOrEmpty(eventFormState.event.categories)) {
    providedFieldCount += 1;
  }
  if (!ArrayUtils.isNullOrEmpty(eventFormState.event.tags)) {
    providedFieldCount += 1;
  }
  if (!ArrayUtils.isNullOrEmpty(eventFormState.event.locations)) {
    providedFieldCount += 1;
  }
  if (!ArrayUtils.isNullOrEmpty(eventFormState.event.media_items)) {
    providedFieldCount += 1;
  }

  if (asNonEmptyString(eventFormState.event.destination_type)) {
    providedFieldCount += 1;
  }
  if (asNonEmptyString(eventFormState.event.destination_subtype)) {
    providedFieldCount += 1;
  }
  if (asNonEmptyString(eventFormState.event.weather_conditions)) {
    providedFieldCount += 1;
  }
  if (asNonEmptyString(eventFormState.event.stroller_condition)) {
    providedFieldCount += 1;
  }
  if (asNonEmptyString(eventFormState.event.is_dogs_allowed)) {
    providedFieldCount += 1;
  }
  if (asNonEmptyString(eventFormState.event.main_theme)) {
    providedFieldCount += 1;
  }
  if (eventFormState.event.number_of_animal_species !== null) {
    providedFieldCount += 1;
  }
  if (asNonEmptyString(eventFormState.event.activity_duration)) {
    providedFieldCount += 1;
  }
  if (eventFormState.event.distance_supply !== null) {
    providedFieldCount += 1;
  }
  if (eventFormState.event.distance_public_transport !== null) {
    providedFieldCount += 1;
  }
  if (asNonEmptyString(eventFormState.event.reachability_options)) {
    providedFieldCount += 1;
  }
  if (eventFormState.event.number_of_parking_spots !== null) {
    providedFieldCount += 1;
  }
  if (asNonEmptyString(eventFormState.event.surroundings_type)) {
    providedFieldCount += 1;
  }
  if (asNonEmptyString(eventFormState.event.shade_type)) {
    providedFieldCount += 1;
  }
  if (asNonEmptyString(eventFormState.event.price_level)) {
    providedFieldCount += 1;
  }

  if (asNonEmptyString(eventFormState.event.contact_firstname)) {
    providedFieldCount += 1;
  }
  if (asNonEmptyString(eventFormState.event.contact_lastname)) {
    providedFieldCount += 1;
  }
  if (asNonEmptyString(eventFormState.event.contact_email)) {
    providedFieldCount += 1;
  }
  if (asNonEmptyString(eventFormState.event.email)) {
    providedFieldCount += 1;
  }
  if (asNonEmptyString(eventFormState.event.responsible_organisation)) {
    providedFieldCount += 1;
  }

  const completionFactor = providedFieldCount / totalFieldCount;
  const progress = completionFactor * 100;

  /* Interpolate color
  0% #F23686 rgb(242,54,134)
  50% #707CDA rgb(112,124,218)
  100% #33B592 rgb(51,181,146)
   */
  let color1 = [242, 54, 134];
  let color2 = [112, 124, 218];
  let colorInterpolationFactor = completionFactor * 2;
  if (completionFactor >= 0.5) {
    color1 = [112, 124, 218];
    color2 = [51, 181, 146];
    colorInterpolationFactor = (completionFactor - 0.5) * 2;
  }

  const color = interpolateColor(color1, color2, colorInterpolationFactor);

  return {
    isMissingImages,
    isMissingBaseData,
    isMissingLocations,
    isMissingTimes,
    progress,
    color,
  };
}

function filterForValidOpeningHours(openingHours?: BCEventRangeOpeningHours[]) {
  return (
    openingHours?.filter(
      (i) =>
        !StringUtils.isNullOrEmpty(i.day_selection) &&
        !StringUtils.isNullOrEmpty(i.time_start) &&
        !StringUtils.isNullOrEmpty(i.time_end),
    ) || []
  );
}
