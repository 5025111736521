import { Company } from "./company";
import { User } from "./user";
import { Media } from "./media";
import { BCLocation } from "./location";

export const allowedEventDayValues = ["mon", "tue", "wed", "thu", "fri", "sat", "sun", "hol"]; //! !!!! don't change the order -> search for allowedEventDayValues[0];!!!
export const allowedEventMonthValues = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];

export enum BCEventType {
  Dedicated = "Dedicated",
  Recurring = "Recurring",
}

export enum BCAvailabilityRangeType {
  AllYear = "AllYear",
  Seasonal = "Seasonal",
  Individual = "Individual",
}

export enum BCEventBookingUrlType {
  Email = "EMAIL",
  Link = "LINK",
}

export enum BCEventStatus {
  DRAFT = "DRAFT",
  READY_FOR_REVIEW = "READY_FOR_REVIEW",
  PUBLISHED = "PUBLISHED",
  UNPUBLISHED = "UNPUBLISHED",
  REJECTED = "REJECTED",
  DELETED = "DELETED",
  FINISHED = "FINISHED",
}

export type BCEventUpgradeType = "none" | "premium" | "premium_plus";

export interface BCEvent {
  event_id: string;
  type: string;

  reject_reason: string;

  promotion_months: string;

  // Field group - 3#
  title: string;
  summary: string;
  description: string;

  // Field group - 5#
  price_in_cents: number;
  price_in_cents_3yr: number;
  price_in_cents_8yr: number;
  price_in_cents_adult: number;
  is_starting_price: boolean;
  price_currency: string;
  price_on_request: boolean;

  is_registration_necessary: boolean;
  location_independent: boolean;

  // Field group - 2#
  phone: string;
  website_url: string | null;
  booking_url: string | null;
  booking_url_type: string | null;

  // Field group - 2#
  age_from: number;
  age_to: number;

  status: BCEventStatus;

  company: Company;

  review_submission_date: string;
  review_submission_user: User;

  // Field group - 2#
  categories: BCEventCategory[];
  tags: BCEventTag[];

  // Field group - 1#
  locations: BCLocation[];

  dedicated_times: BCEventDedicatedTime[];

  event_range_type?: string;
  event_availability_ranges: BCEventAvailabilityRange[];

  // Field group - 1#
  media_items: Media[];

  is_ready_to_review: boolean;
  not_reviewable_reasons: string[];

  is_indoor_event: boolean;
  is_outdoor_event: boolean;

  has_a_published_version: boolean;
  event_upgrade: BCEventUpgradeType;
  event_upgrade_suspended: boolean;
  booked_hero_slots: BCHeroSlot[];

  company_slug: string;
  normalized_title: string;

  // Field group - 15# (without opening_hours)
  phone_alternative: string;
  fax: string;
  destination_type: string;
  destination_subtype: string;
  weather_conditions: string;
  is_winter_destination: boolean;
  is_accessible: boolean;
  stroller_condition: string;
  is_dogs_allowed: string;
  opening_hours: [BCEventOpeningHours];
  notes_opening_hours: string;
  notes_pricing: string;
  main_theme: string;
  number_of_animal_species: number | null;
  activity_duration: string;
  is_available_for_kids_birthday_parties: boolean;
  is_restaurant_available: boolean;
  is_snackbar_available: boolean;
  distance_supply: number | null;
  is_toilet_available: boolean;
  is_diaper_changing_available: boolean;
  distance_public_transport: number | null;
  reachability_options: string;
  is_parking_available: boolean;
  number_of_parking_spots: number | null;
  surroundings_type: string;
  shade_type: string;
  price_level: string;

  // Field group - 2#
  type_of_event: string;
  event_highlights: string;
  is_online_event: boolean;
  is_hosted_with_bad_weather: boolean;
  is_childcare_available: boolean;

  // Field group - 5#
  contact_title: string;
  contact_firstname: string;
  contact_lastname: string;
  contact_email: string;
  email: string;
  responsible_organisation: string;

  has_special_category?: boolean;

  has_school_offers: boolean;

  is_discount_active: boolean;
  discount_description_short: string;
  discount_description_long: string;

  video_link: string | null;

  needs_review: boolean;
}

export interface BCEventCategory {
  category_id: string;
  identifier: string;
  is_locked_custom_category: boolean;
  is_exclusive: boolean;
}

export interface BCEventTag {
  tag_id: string;
  name: string;
}

export interface BCEventDedicatedTime {
  event_dedicated_time_id?: string;
  start_date?: Date;
  end_date?: Date;
}

export interface BCEventOpeningHours {
  event_opening_hours_id?: string;
  day_selection?: string;
  time_start?: string;
  time_end?: string;
}

export interface BCEventAvailabilityRange {
  event_availability_range_id?: string;
  start_date?: Date;
  end_date?: Date;
  opening_hours: BCEventRangeOpeningHours[];
  is_all_year: boolean;
  is_seasonal_range: boolean;
}

export interface BCEventRangeOpeningHours {
  event_range_opening_hours_id?: string;
  day_selection?: string;
  time_start?: string;
  time_end?: string;
}

export interface BCHeroSlot {
  name: string;
  slot_end_date: string;
  slot_id: string;
  slot_start_date: string;
  slot_week: number;
  slot_year: number;
}
